@import '../../styles/customMediaQueries.css';

.listingCard {
  width: 100%;
}
.listingCards {
  padding-bottom: 24px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.contentWrapper {
  padding: 0 24px 0 24px;
  margin-bottom: 32px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionTitle {
  /* Font for sections titles */
  composes: h3 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
}
