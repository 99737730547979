@import '../../styles/customMediaQueries.css';

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.feedContainer {
  margin: 0 15px 0 20px;
  padding: 0px 12px 0 0;
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  /* border: 2px solid red; */

  @media (--viewportMedium) {
    max-height: 60vh;
  }

  @media (--viewportLarge) {
    max-height: 400px;
    padding: 0;
    margin: 0;
  }
}

.messageFrameContainer {
  padding-top: 80px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.feedContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.messageContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
    border-top: 0;
  }

  @media (--viewportLarge) {
    margin: 48px 0 0 0;
  }
}

.contactDonor {
  composes: h3 from global;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;

  @media (--viewportMaxLarge) {
    display: none;
  }
}

.sendMessageForm {
  position: relative;
  padding: 24px;

  @media (--viewportMedium) {
    border-top: 0;
  }

  @media (--viewportLarge) {
    padding: 0;
    padding-top: 24px;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  line-height: 24px;
  margin: 0 15px 0 20px;
  padding: 0px 12px 0 0;

  @media (--viewportMedium) {
    border-top: 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 0 0;
    padding: 0;
  }
}

.signUpButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  @media (--viewportLarge) {
    margin-top: 30px;
  }
}

.footer {
  @media (--viewportMaxLarge) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowTop);
    z-index: 9999;
  }
}

.messageDonor {
  display: none;

  @media (--viewportMaxLarge) {
    display: inline;
  }
}

.contactDonorContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  display: none;
  color: var(--matterColorAnti);

  @media (--viewportLarge) {
    margin-left: 8px;
    display: block;
  }
}

.tooltipTextTop {
  bottom: 100%;
  left: 50%;
  margin-left: -80px;
}

.tooltipTextTop::after {
  top: 100%;
  left: 66%;
  margin-left: -5px;
  border-color: black transparent transparent transparent;
}

.mobileMessageDonorTip {
  composes: successAlert from global;
  margin: 0 20px 10px 20px;

  @media (--viewportLarge) {
    display: none;
  }
}
